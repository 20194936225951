import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 512.000000 512.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M3450 4793 c-14 -1 -74 -7 -135 -13 -60 -5 -135 -14 -165 -20 -30 -7
-80 -16 -110 -21 -55 -10 -139 -31 -195 -50 -16 -6 -61 -19 -99 -29 -37 -10
-73 -23 -79 -29 -6 -6 -20 -11 -31 -11 -12 0 -30 -7 -40 -15 -11 -8 -28 -15
-38 -15 -10 0 -26 -4 -36 -9 -9 -5 -51 -23 -92 -40 -130 -52 -438 -213 -500
-261 -19 -15 -46 -33 -60 -40 -13 -7 -57 -37 -97 -66 -39 -30 -77 -54 -82 -54
-6 0 -11 -4 -11 -10 0 -5 -5 -10 -11 -10 -6 0 -29 -16 -52 -35 -23 -20 -85
-69 -137 -111 -52 -41 -137 -114 -188 -160 l-92 -86 -133 -4 c-72 -2 -145 -8
-162 -13 -16 -5 -47 -12 -67 -16 -21 -3 -38 -10 -38 -15 0 -6 -5 -10 -12 -10
-6 0 -36 -12 -67 -27 -99 -49 -209 -191 -249 -323 -6 -19 -16 -83 -23 -142 -9
-87 -9 -126 1 -200 7 -51 16 -97 21 -103 4 -5 10 -26 14 -45 5 -30 -2 -54 -48
-150 -82 -172 -91 -195 -134 -325 -47 -140 -58 -180 -73 -255 -6 -30 -16 -68
-22 -85 -5 -16 -12 -138 -15 -270 -5 -229 0 -313 26 -420 6 -22 13 -53 16 -70
4 -16 15 -49 26 -72 10 -24 19 -46 19 -51 0 -5 9 -27 20 -50 11 -22 20 -46 20
-52 0 -5 4 -10 9 -10 5 0 11 -8 14 -17 7 -21 56 -106 66 -113 3 -3 18 -21 32
-40 24 -33 86 -95 202 -205 27 -25 55 -45 63 -45 8 0 14 -4 14 -9 0 -5 21 -19
48 -31 26 -12 76 -37 112 -56 36 -18 74 -34 85 -34 11 0 29 -7 39 -15 11 -8
29 -15 41 -15 12 0 25 -4 31 -9 10 -11 94 -28 199 -41 39 -5 113 -14 165 -20
98 -13 272 -8 385 10 33 5 101 14 150 21 50 6 110 17 135 24 25 8 72 21 105
30 33 9 74 21 91 26 17 5 40 9 51 9 12 0 28 7 37 15 8 8 26 15 39 15 14 0 30
7 37 15 7 8 25 15 41 15 15 0 31 5 34 10 3 6 17 10 31 10 14 0 27 7 30 15 4 8
19 15 34 15 16 0 31 4 34 9 3 4 19 11 36 14 16 4 30 11 30 17 0 15 -37 12 -54
-5 -8 -8 -26 -15 -40 -15 -14 0 -28 -5 -31 -10 -3 -6 -15 -10 -25 -10 -10 0
-31 -6 -47 -14 -36 -18 -94 -36 -116 -36 -9 0 -31 -6 -49 -14 -56 -24 -130
-44 -203 -56 -38 -7 -88 -16 -110 -22 -40 -10 -88 -16 -292 -38 -86 -9 -135
-9 -215 0 -193 22 -243 30 -308 45 -36 9 -81 20 -100 24 -19 5 -48 14 -65 21
-16 7 -46 19 -65 27 -19 8 -43 18 -52 23 -10 6 -22 10 -27 10 -10 0 -153 79
-161 89 -3 4 -22 17 -42 30 -51 31 -209 200 -268 286 -26 39 -66 111 -90 160
-56 118 -55 115 -91 250 -64 240 -52 595 31 880 6 22 14 52 17 68 3 15 9 30
14 33 5 3 9 14 9 25 0 11 9 39 20 61 11 23 20 45 20 50 0 9 21 57 76 176 30
63 54 91 54 62 0 -17 129 -147 163 -164 17 -9 46 -25 62 -36 17 -10 55 -26 85
-35 30 -9 67 -23 82 -31 14 -8 34 -14 44 -14 51 0 187 -63 220 -103 39 -46 33
-89 -21 -141 -52 -51 -71 -56 -235 -63 -117 -5 -231 8 -280 32 -14 7 -41 19
-60 28 -19 8 -55 24 -79 36 -24 12 -45 20 -47 18 -4 -4 -6 -478 -2 -481 36
-19 95 -34 220 -55 31 -5 62 -12 67 -16 6 -3 41 -10 79 -15 37 -5 102 -14 144
-21 93 -14 104 -9 113 59 20 139 132 387 256 566 77 111 87 123 212 258 l118
127 -27 43 c-31 48 -33 51 -90 96 -23 17 -49 32 -57 32 -9 0 -19 5 -22 10 -3
6 -16 10 -29 10 -12 0 -26 4 -31 9 -13 10 -108 41 -131 41 -9 0 -39 7 -68 17
-49 16 -69 23 -148 57 -54 24 -78 63 -78 129 0 48 4 59 29 82 16 15 54 36 85
47 51 18 64 19 119 8 34 -7 80 -15 102 -19 22 -5 51 -13 65 -19 31 -12 129
-42 141 -42 5 0 9 105 9 235 l0 234 -32 17 c-32 17 -100 30 -200 41 -63 6 -71
17 -34 48 13 11 57 51 99 89 42 38 101 87 131 110 30 22 87 66 128 97 40 30
120 86 178 124 58 38 107 72 110 75 7 7 42 29 60 37 8 4 17 9 20 13 3 4 48 28
100 54 52 27 115 59 140 71 25 13 72 36 105 50 33 15 74 33 90 41 82 37 382
136 470 154 33 6 78 16 100 22 22 5 67 14 100 18 33 5 78 14 100 19 22 6 152
11 291 11 198 0 260 -3 294 -15 23 -8 54 -15 67 -15 14 0 46 -7 72 -15 25 -7
66 -19 91 -25 25 -7 60 -18 78 -26 17 -8 47 -20 65 -28 83 -36 189 -91 199
-103 6 -7 14 -13 18 -13 16 0 210 -182 255 -240 101 -129 192 -316 227 -465
47 -203 54 -442 17 -675 -5 -36 -12 -82 -15 -103 -4 -20 -10 -40 -15 -43 -5
-3 -9 -23 -9 -45 0 -21 -4 -39 -10 -39 -5 0 -10 -14 -10 -31 0 -16 4 -28 9
-25 8 5 15 30 28 104 3 18 9 35 14 38 5 3 9 20 9 38 0 17 4 36 8 42 5 5 14 42
20 82 7 39 17 102 23 140 21 129 3 466 -31 587 -7 22 -14 52 -17 68 -3 15 -9
30 -14 33 -5 3 -9 13 -9 23 0 34 -85 200 -153 301 -33 48 -177 195 -248 252
-24 19 -69 48 -99 64 -30 16 -61 35 -68 41 -7 7 -20 13 -28 13 -8 0 -22 4 -32
9 -67 34 -159 68 -217 80 -27 5 -84 17 -125 25 -132 27 -355 42 -440 29z"/>
<path d="M1696 3642 c-9 -45 -5 -928 4 -942 7 -11 19 -5 53 26 24 21 52 46 63
54 11 8 42 33 69 56 72 58 197 140 245 161 23 10 43 27 46 38 2 11 3 157 2
325 l-3 305 -237 2 -237 2 -5 -27z"/>
<path d="M2616 3648 c-9 -12 -16 -28 -16 -35 0 -7 -4 -13 -10 -13 -5 0 -10 -6
-10 -12 0 -7 -50 -113 -110 -236 -61 -123 -110 -225 -110 -227 0 -13 28 -4
115 36 68 31 338 99 394 99 50 0 74 17 98 70 6 14 43 91 82 171 39 81 71 152
71 158 0 8 -72 11 -244 11 -242 0 -245 0 -260 -22z"/>
<path d="M3140 3656 c0 -13 33 -104 50 -136 4 -9 11 -36 15 -60 4 -24 11 -51
15 -60 4 -8 19 -42 32 -75 21 -54 27 -60 58 -66 40 -8 84 -23 157 -56 28 -13
55 -23 58 -23 4 0 24 -13 45 -30 21 -16 41 -30 45 -30 3 0 24 -14 45 -31 78
-63 173 -206 200 -299 6 -25 16 -56 22 -70 15 -38 21 -227 9 -310 -15 -113
-25 -157 -55 -243 -24 -72 -139 -315 -155 -327 -3 -3 -10 -12 -16 -21 -5 -9
-23 -35 -38 -58 -16 -24 -27 -45 -25 -47 2 -2 134 -4 293 -4 l290 -2 2 299 c3
268 5 302 22 338 10 22 31 69 46 105 15 36 38 89 51 118 13 29 24 55 24 57 0
3 8 24 19 48 54 122 121 286 127 312 4 17 19 57 34 90 15 33 37 85 50 115 45
109 50 121 65 155 8 19 22 51 30 70 37 85 48 111 55 130 4 11 15 36 24 57 32
72 47 68 -259 67 l-276 0 -11 -27 c-6 -15 -14 -43 -19 -62 -4 -19 -13 -53 -19
-75 -12 -41 -22 -81 -39 -150 -5 -22 -15 -58 -21 -80 -6 -22 -16 -57 -21 -77
-5 -21 -13 -56 -19 -78 -5 -22 -15 -59 -20 -82 -20 -79 -29 -113 -40 -153 -7
-22 -14 -57 -17 -77 -5 -40 -30 -71 -37 -47 -2 8 -9 34 -17 59 -7 25 -16 49
-21 55 -4 5 -8 16 -8 25 0 9 -18 72 -40 140 -22 68 -40 130 -40 137 0 7 -4 21
-9 31 -5 9 -19 51 -31 92 -12 41 -26 86 -30 100 -4 14 -18 59 -30 100 -12 41
-26 83 -31 92 -5 10 -9 24 -9 32 0 8 -7 22 -16 30 -13 14 -51 16 -265 16 -204
0 -249 -2 -249 -14z"/>
<path d="M4800 2789 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5
0 -10 -9 -10 -21z"/>
<path d="M4787 2753 c-11 -10 -8 -43 3 -43 6 0 10 11 10 25 0 26 -2 29 -13 18z"/>
<path d="M4767 2694 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23
-2 6 -7 8 -11 4z"/>
<path d="M4747 2643 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M4720 2584 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M4705 2541 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M3080 799 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3026 781 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4
-12 2 -15 -3z"/>
<path d="M2980 750 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2924 729 c-9 -16 2 -22 21 -11 15 8 16 10 1 15 -8 3 -18 2 -22 -4z"/>
<path d="M2865 701 c-3 -5 -1 -12 5 -16 12 -7 30 2 30 16 0 12 -27 12 -35 0z"/>
<path d="M2815 670 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10 -8
0 -18 -4 -21 -10z"/>
<path d="M2755 649 c-10 -15 1 -22 24 -14 12 3 19 11 16 16 -8 12 -32 11 -40
-2z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
